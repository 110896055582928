// extracted by mini-css-extract-plugin
export var area = "EnterpriseDetails-module--area--7fba9";
export var arrow = "EnterpriseDetails-module--arrow--a83d2";
export var bar = "EnterpriseDetails-module--bar--f2a6a";
export var beachfront = "EnterpriseDetails-module--beachfront--117b4";
export var bed = "EnterpriseDetails-module--bed--6a93b";
export var btnWrapper = "EnterpriseDetails-module--btn-wrapper--5d681";
export var car = "EnterpriseDetails-module--car--8ae07";
export var commercialRoom = "EnterpriseDetails-module--commercialRoom--cc583";
export var container = "EnterpriseDetails-module--container--ec31e";
export var content = "EnterpriseDetails-module--content--0f69a";
export var elements = "EnterpriseDetails-module--elements--a1ce4";
export var form = "EnterpriseDetails-module--form--3c42e";
export var image = "EnterpriseDetails-module--image--80451";
export var intro = "EnterpriseDetails-module--intro--cf1ed";
export var knowMore = "EnterpriseDetails-module--knowMore--71fc8";
export var legal = "EnterpriseDetails-module--legal--b4c24";
export var light = "EnterpriseDetails-module--light--7fc85";
export var links = "EnterpriseDetails-module--links--e1f72";
export var logo = "EnterpriseDetails-module--logo--ca6c2";
export var numbers = "EnterpriseDetails-module--numbers--e4612";
export var obs = "EnterpriseDetails-module--obs--f6d69";
export var parking = "EnterpriseDetails-module--parking--efcc2";
export var plant = "EnterpriseDetails-module--plant--e74ee";
export var plantSlider = "EnterpriseDetails-module--plant-slider--a7061";
export var plantsWork = "EnterpriseDetails-module--plants-work--f8e76";
export var slide = "EnterpriseDetails-module--slide--d32ce";
export var slider = "EnterpriseDetails-module--slider--25759";
export var stages = "EnterpriseDetails-module--stages--f100f";
export var types = "EnterpriseDetails-module--types--3d86c";
export var video = "EnterpriseDetails-module--video--4bd78";
export var videoMosaic = "EnterpriseDetails-module--video-mosaic--57232";
export var videoWrapper = "EnterpriseDetails-module--videoWrapper--c9dcb";
export var workImages = "EnterpriseDetails-module--work-images--553b9";